var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.error.length > 0
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Ocurrió un error al obtener las ordenes " + _vm._s(_vm.error) + " ")])]), _vm.loading ? _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "45vh 0"
    }
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('b-card', {
    attrs: {
      "title": "Ordenes"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-1"
  }, [_vm._v("Buscar")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "type": "text",
      "placeholder": "Buscar"
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)])], 1), _c('vue-good-table', {
    attrs: {
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "search-options": {
        enabled: true,
        externalQuery: _vm.searchTerm
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-row-click": _vm.onOrderClick
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'fechacreacion' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.formattedDate(props.row.fechacreacion)))]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.formattedHour(props.row.fechacreacion)))])]) : props.column.field === 'origen' ? _c('span', [props.row.origen == 1 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/se1.png'),
            "width": "25"
          }
        }) : _vm._e(), props.row.origen == 2 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/se2.png'),
            "width": "25"
          }
        }) : _vm._e(), props.row.origen == 3 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/rappi.png'),
            "width": "25"
          }
        }) : _vm._e(), props.row.origen == 4 ? _c('b-img', {
          attrs: {
            "src": require('@/assets/images/misc/lite.png'),
            "width": "25"
          }
        }) : _vm._e()], 1) : props.column.field === 'idorden' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(props.row.idorden))]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(props.row.numero_orden_se1))])]) : props.column.field === 'tipoorden' ? _c('div', {
          staticClass: "cnt"
        }, [_c('b-img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          attrs: {
            "src": _vm.tipoIMG(props.row.tipoorden),
            "width": "35",
            "height": "35",
            "title": _vm.tipoText(props.row.tipoorden)
          }
        })], 1) : props.column.field === 'estadoorden' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant(props.row.estadopicking)
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText(props.row.estadopicking)) + " ")]), props.row.estadopicking == 'CE' && !_vm.isComplete(props.row.lstEstadosPicking) ? _c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Incompleta ")]) : _vm._e()], 1) : props.column.field === 'tipoentrega' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.entregaVariant(props.row.tipoentrega)
          }
        }, [_vm._v(" " + _vm._s(_vm.entregaText(props.row.tipoentrega)) + " ")]), props.row.tipoentrega === 'PG' ? _c('div', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.formattedDate(props.row.fechaprogramada)))]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.formattedHour(props.row.fechaprogramada)))])]) : _vm._e()], 1) : props.column.field === 'cliente' ? _c('div', {
          staticClass: "clt"
        }, [props.row.tipocliente !== 'NORMAL' && props.row.tipocliente !== '' ? _c('div', [_c('b-img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          attrs: {
            "src": _vm.clienteVariant(props.row.tipocliente),
            "width": "38",
            "height": "38",
            "title": _vm.clienteText(props.row.tipocliente)
          }
        })], 1) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(props.row.cliente.length ? props.row.cliente[0].nombreCompleto : "") + " ")])]) : props.column.field === 'samplings' ? _c('span', [props.row.samplings && props.row.samplings.length != 0 ? _c('div', [_c('div', {
          staticStyle: {
            "display": "flex",
            "justify-content": "center"
          }
        }, [_c('b-avatar', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "rounded": "",
            "variant": "light-secondary",
            "size": "25"
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "color": "#04a82c"
          },
          attrs: {
            "icon": "GiftIcon",
            "size": "18"
          },
          on: {
            "click": function click(event) {
              event.stopPropagation();
              _vm.onSamplingsClick(props.row.samplings, props.row.idorden);
            }
          }
        })], 1)], 1), _c('b-button', {
          staticStyle: {
            "font-size": "11.05px",
            "padding": "0.3rem 0.5rem",
            "color": "#04a82c"
          },
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click(event) {
              event.stopPropagation();
              _vm.onSamplingsClick(props.row.samplings, props.row.idorden);
            }
          }
        }, [_vm._v("Ver Samplings")])], 1) : _c('div', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" -- ")])])]) : props.column.field === 'repartidor' ? _c('span', [_vm._v(" " + _vm._s(props.row.lstRepartidor.length > 0 ? props.row.lstRepartidor[0].nombrecompleto : "Sin Asignar") + " ")]) : props.column.field === 'bodega' ? _c('span', [_vm._v(" " + _vm._s("".concat(props.row.bodega).replace("Bodega ", "")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Cantidad de ordenes ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        })], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "align": "right",
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "prev-class": "prev-item",
            "next-class": "next-item",
            "last-number": "",
            "first-number": ""
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "emptystate"
    },
    slot: "emptystate"
  }, [_vm._v("No hay ordenes")]), _c('div', {
    attrs: {
      "slot": "table-actions"
    },
    slot: "table-actions"
  }, [_c('b-form-group', {
    staticClass: "mt-1 mr-1"
  }, [_c('b-form-radio-group', {
    attrs: {
      "button-variant": "outline-primary",
      "options": _vm.filterOptions,
      "buttons": ""
    },
    on: {
      "change": _vm.filterOrders
    },
    model: {
      value: _vm.selectedFilter,
      callback: function callback($$v) {
        _vm.selectedFilter = $$v;
      },
      expression: "selectedFilter"
    }
  })], 1)], 1)]), _c('b-modal', {
    attrs: {
      "title": "Error",
      "ok-only": "",
      "centered": "",
      "ok-title": "Accept",
      "ok-variant": "danger",
      "modal-class": "modal-danger"
    },
    model: {
      value: _vm.showErrorModal,
      callback: function callback($$v) {
        _vm.showErrorModal = $$v;
      },
      expression: "showErrorModal"
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1), _c('b-modal', {
    attrs: {
      "title": 'Samplings de la orden #' + _vm.idOrden,
      "ok-only": "",
      "centered": "",
      "ok-title": "Entendido"
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('vue-good-table', {
    attrs: {
      "rows": _vm.samplings,
      "columns": _vm.columnsSamplings,
      "sort-options": {
        enabled: false
      },
      "search-options": {
        enabled: false
      },
      "pagination-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'nombre' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.nombre === "" ? "--" : props.row.nombre) + " ")])]) : props.column.field === 'descripcion' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.descripcion === "" ? "--" : props.row.descripcion) + " ")])]) : props.column.field === 'codigo' ? _c('span', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(props.row.codigo === "" ? "--" : props.row.codigo) + " ")])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "emptystate"
  }, [_vm._v("No hay samplings para mostrar")])], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }