<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener las ordenes {{ error }}
      </div>
    </b-alert>

    <div style="text-align: center; margin: 45vh 0" v-if="loading">
      <b-spinner label="Loading..." />
    </div>

    <b-card title="Ordenes" v-else>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Buscar</label>
            <b-form-input
              type="text"
              class="d-inline-block"
              v-model="searchTerm"
              placeholder="Buscar"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :rows="rows"
        :columns="columns"
        @on-row-click="onOrderClick"
        :sort-options="{
          enabled: false,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <div slot="emptystate">No hay ordenes</div>
        <div slot="table-actions">
          <!-- checkbox button  -->
          <b-form-group class="mt-1 mr-1">
            <b-form-radio-group
              v-model="selectedFilter"
              button-variant="outline-primary"
              :options="filterOptions"
              @change="filterOrders"
              buttons
            />
          </b-form-group>
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Fecha -->
          <span v-if="props.column.field === 'fechacreacion'">
            <p class="mb-0">{{ formattedDate(props.row.fechacreacion) }}</p>
            <p class="mb-0">{{ formattedHour(props.row.fechacreacion) }}</p>
          </span>
          <!-- Column: Origen -->
          <span v-else-if="props.column.field === 'origen'">
            <b-img
              v-if="props.row.origen == 1"
              :src="require('@/assets/images/misc/se1.png')"
              width="25"
            />
            <b-img
              v-if="props.row.origen == 2"
              :src="require('@/assets/images/misc/se2.png')"
              width="25"
            />
            <b-img
              v-if="props.row.origen == 3"
              :src="require('@/assets/images/misc/rappi.png')"
              width="25"
            />
            <b-img
              v-if="props.row.origen == 4"
              :src="require('@/assets/images/misc/lite.png')"
              width="25"
            />
          </span>
          <!-- Column: Numero -->
          <span v-else-if="props.column.field === 'idorden'">
            <p class="mb-0">{{ props.row.idorden }}</p>
            <p class="mb-0">{{ props.row.numero_orden_se1 }}</p>
          </span>
          <!-- Column: Tipo Orden -->
          <div v-else-if="props.column.field === 'tipoorden'" class="cnt">
            <b-img
              :src="tipoIMG(props.row.tipoorden)"
              width="35"
              height="35"
              :title="tipoText(props.row.tipoorden)"
              v-b-tooltip
            />
          </div>
          <!-- Column: Estado -->
          <span v-else-if="props.column.field === 'estadoorden'">
            <b-badge :variant="statusVariant(props.row.estadopicking)">
              {{ statusText(props.row.estadopicking) }}
            </b-badge>

            <b-badge
              variant="light-danger"
              v-if="
                props.row.estadopicking == 'CE' &&
                !isComplete(props.row.lstEstadosPicking) "              
            >
              Incompleta
            </b-badge>
          </span>
          <!-- Column: Entrega -->
          <span v-else-if="props.column.field === 'tipoentrega'">
            <b-badge :variant="entregaVariant(props.row.tipoentrega)">
              {{ entregaText(props.row.tipoentrega) }}
            </b-badge>
            <div v-if="props.row.tipoentrega === 'PG'">
              <p class="mb-0">{{ formattedDate(props.row.fechaprogramada) }}</p>
              <p class="mb-0">{{ formattedHour(props.row.fechaprogramada) }}</p>
            </div>
          </span>
          <!-- Column: Cliente -->
          <div v-else-if="props.column.field === 'cliente'" class="clt">
            <div
              v-if="
                props.row.tipocliente !== 'NORMAL' &&
                  props.row.tipocliente !== ''
              "
            >
              <b-img
                :src="clienteVariant(props.row.tipocliente)"
                width="38"
                height="38"
                :title="clienteText(props.row.tipocliente)"
                v-b-tooltip
              />
            </div>
            <div>
              {{
                props.row.cliente.length
                  ? props.row.cliente[0].nombreCompleto
                  : ""
              }}
            </div>
          </div>
          <!-- Column: Samplings -->
          <span v-else-if="props.column.field === 'samplings'">
            <div v-if="props.row.samplings && props.row.samplings.length != 0">
              <div style="display: flex; justify-content: center;">
                <b-avatar
                  rounded
                  variant="light-secondary"
                  size="25"
                  style="cursor: pointer;"
                >
                  <feather-icon
                    icon="GiftIcon"
                    size="18"
                    style="color: #04a82c;"
                    @click="
                      (event) => {
                        event.stopPropagation();
                        onSamplingsClick(
                          props.row.samplings,
                          props.row.idorden
                        );
                      }
                    "
                  />
                </b-avatar>
              </div>
              <b-button
                variant="success"
                style="font-size: 11.05px; padding: 0.3rem 0.5rem; color: #04a82c;"
                @click="
                  (event) => {
                    event.stopPropagation();
                    onSamplingsClick(props.row.samplings, props.row.idorden);
                  }
                "
                >Ver Samplings</b-button
              >
            </div>
            <div v-else>
              <p class="mb-0">
                --
              </p>
            </div>
          </span>
          <!-- Column: Motorizado -->
          <span v-else-if="props.column.field === 'repartidor'">
            {{
              props.row.lstRepartidor.length > 0
                ? props.row.lstRepartidor[0].nombrecompleto
                : "Sin Asignar"
            }}
          </span>
          <!-- Column: Bodega -->
          <span v-else-if="props.column.field === 'bodega'">
            {{ `${props.row.bodega}`.replace("Bodega ", "") }}
          </span>
          <!-- Column: Otras Columnas -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Cantidad de ordenes </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '15', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
            </div>
            <div>
              <b-pagination
                align="right"
                class="mt-1 mb-0"
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                prev-class="prev-item"
                next-class="next-item"
                last-number
                first-number
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        title="Error"
        ok-only
        v-model="showErrorModal"
        centered
        ok-title="Accept"
        ok-variant="danger"
        modal-class="modal-danger"
      >
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
      <b-modal
        :title="'Samplings de la orden #' + idOrden"
        ok-only
        v-model="showModal"
        centered
        ok-title="Entendido"
      >
        <vue-good-table
          :rows="samplings"
          :columns="columnsSamplings"
          :sort-options="{
            enabled: false,
          }"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="emptystate">No hay samplings para mostrar</template>

          <template slot="table-row" slot-scope="props">
            <!-- Column: Nombre -->
            <span v-if="props.column.field === 'nombre'">
              <p class="mb-0">
                {{ props.row.nombre === "" ? "--" : props.row.nombre }}
              </p>
            </span>
            <!-- Column: Descripcion -->
            <span v-else-if="props.column.field === 'descripcion'">
              <p class="mb-0">
                {{
                  props.row.descripcion === "" ? "--" : props.row.descripcion
                }}
              </p>
            </span>
            <!-- Column: Codigo -->
            <span v-else-if="props.column.field === 'codigo'">
              <p class="mb-0">
                {{ props.row.codigo === "" ? "--" : props.row.codigo }}
              </p>
            </span>
            <!-- Column: Otras Columnas -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-modal>
    </b-card>
    <!-- <socket-market :warehouse="bodegaID" :on-new-order="onNewOrder" /> -->
  </div>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BAlert,
  BButton,
  BBadge,
  BAvatar,
  BSpinner,
  VBTooltip,
  BCardText,
  BDropdown,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BDropdownItem,
  BFormRadioGroup,
} from "bootstrap-vue";

import moment from "moment";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BAlert,
    BBadge,
    BButton,
    BAvatar,
    BSpinner,
    BDropdown,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    VueGoodTable,
    BDropdownItem,
    BFormRadioGroup,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      rows: [],
      error: "",
      unwatch: null,
      ordenes: null,
      loading: true,
      bodegaID: 0,
      searchTerm: "",
      pageLength: 10,
      showErrorModal: false,
      selectedFilter: "CR",
      filterOptions: [
        { text: "Creadas", value: "CR" },
        { text: "En preparacion", value: "EP" },
        //{ text: "Completas", value: "C" },
        //{ text: "Incompletas", value: "IN" },
        { text: "Cerradas", value: "CE" },
        { text: "Anuladas", value: "AN" },
      ],
      samplings: [],
      showModal: false,
      idOrden: 0,
      columns: [
        {
          label: "Origen",
          field: "origen",
        },
        {
          label: "Fecha / Hora",
          field: "fechacreacion",
        },
        {
          label: "Numero",
          field: "idorden",
        },
        {
          label: "Tipo orden",
          field: "tipoorden",
        },
        {
          label: "PromoCode",
          field: "promocode",
        },
        {
          label: "Cliente",
          field: "cliente",
        },
        {
          label: "Samplings",
          field: "samplings",
        },
        {
          label: "Entrega",
          field: "tipoentrega",
        },
        {
          label: "Estado",
          field: "estadoorden",
        },
        {
          label: "Bodega",
          field: "bodega",
        },
        {
          label: "Picker",
          field: "picker",
        },
        {
          label: "Motorizado",
          field: "repartidor",
        },
      ],
      columnsSamplings: [
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Descripción",
          field: "descripcion",
        },
        {
          label: "Codigo",
          field: "codigo",
        },
      ],
    };
  },
  mounted() {
    this.getOrderData();
    this.onWarehouseSelectedChange();
    this.listenSocketOrders();
  },
  beforeDestroy() {
    this.unwatch();
    this.unwatchSocketOrder && this.unwatchSocketOrder();
  },
  computed: {
    clienteText() {
      const cliente = {
        "1ERCOMPRA": "Primera Compra",
        "2DACOMPRA": "Segunda Compra",
        "3ERCOMPRA": "Tercera Compra",
        VIP: "V.I.P.",
        NUEVO: "Nuevo",
        LATEBUY: "Latebuy",
        RECUPERADO: "Recuperado",
      };
      return (c) => cliente[c];
    },
    clienteVariant() {
      const cliente = {
        "1ERCOMPRA": require("../../assets/images/icons/gold-medal.png"),
        "2DACOMPRA": require("../../assets/images/icons/silver-medal.png"),
        "3ERCOMPRA": require("../../assets/images/icons/bronze-medal.png"),
        VIP: require("../../assets/images/icons/vip.png"),
        CVIP: require("../../assets/images/icons/vip.png"),
        NUEVO: require("../../assets/images/icons/new.png"),
        CNUEVO: require("../../assets/images/icons/new.png"),
        RECUPERADO: require("../../assets/images/icons/return.png"),
        CRECUPERADO: require("../../assets/images/icons/return.png"),
        LATEBUY: require("../../assets/images/icons/overdue.png"),
        CLATEBUY: require("../../assets/images/icons/overdue.png"),
      };
      return (c) => cliente[c];
    },
    tipoText() {
      const tipo = {
        UF: "Ultra Flash",
        FS: "Flash",
        MD: "Mediana",
        GD: "Grande",
      };
      return (i) => tipo[i];
    },
    tipoIMG() {
      const tipo = {
        UF: require("../../assets/images/icons/flash-sale.png"),
        FS: require("../../assets/images/icons/flash.png"),
        MD: require("../../assets/images/icons/medium.png"),
        GD: require("../../assets/images/icons/extra-large.png"),
      };
      return (i) => tipo[i];
    },
    entregaText() {
      const status = {
        PG: "Programada",
        IM: "Inmediata",
      };
      return (s) => status[s];
    },
    entregaVariant() {
      const statusColor = {
        PG: "light-info",
        IM: "light-success",
      };
      return (s) => statusColor[s];
    },
    statusText() {
      const status = {
        CR: "Creada",
        AN: "Anulada",
        EP: "En preparación",
        C: "Completa",
        IN: "Incompleta",
        CE: "Cerrada",
      };
      return (s) => status[s];
    },
    statusVariant() {
      const statusColor = {
        CR: "light-info",
        AN: "light-danger",
        EP: "light-info",
        C: "light-info",
        IN: "light-danger",
        CE: "light-success",
      };
      return (s) => statusColor[s];
    },
  },
  methods: {
    filterOrders() {
      this.rows = [];
      this.rows =
        this.ordenes &&
        this.ordenes.filter(
          (o) => o.estadopicking === this.selectedFilter //&& o.cliente.length !== 0
        );
    },
    onNewOrder(order) {
      const index = this.rows.findIndex((o) => o.idorden === order.idorden);
      if (index === -1) this.rows.unshift(order);
      else this.rows[index] = order;
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("hh:mm a");
    },
    onOrderClick(params) {
      this.$router.push({
        name: "picking-orden-detalle",
        params: { id: params.row.idorden, orden: params.row },
      });
    },
    onNewOrder() {
      this.getOrderData(false);
    },
    getOrderData(setLoading = true) {
      if (setLoading) this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetOrdersPicking",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.ordenes = res.data.lstOrdenes;
            this.filterOrders();
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
          if (setLoading) this.loading = false;
        })
        .catch((e) => {
          this.error = e.message;
          if (setLoading) this.loading = false;
          this.showErrorModal = true;
        });
    },
    onWarehouseSelectedChange() {
      this.unwatch = this.$store.watch(
        (_, g) => {
          return g["app/warehouseSelected"];
        },
        (_, __) => {
          this.getOrderData();
        }
      );
    },
    listenSocketOrders() {
      this.unwatchSocketOrder = this.$store.watch(
        (_, g) => {
          return g["app/socketOrder"];
        },
        (_) => {
          this.onNewOrder();
        }
      );
    },
    onSamplingsClick(samplings, idOrden) {
      this.samplings = samplings;
      this.idOrden = idOrden;
      this.showModal = true;
    },
    isComplete(lstEstadosPicking) {
      return !lstEstadosPicking.some((e) => e.codigo === "IN");
    },
  },
};
</script>

<style lang="scss">
table.vgt-table {
  font-size: 13px !important;
}

.cnt {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clt {
  display: flex;
  align-items: center;
  justify-content: center;
}

table.vgt-table tr.clickable:hover {
  background-color: rgba(4, 168, 44, 0.1) !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
